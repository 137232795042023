@import 'styles/variables';
@import 'styles/mixins';

.topheader-container {
    background-color: $color-white-2;
    height: $height-topbar;
    width: calc(100% - #{$width-leftnav});
    position: fixed;
    top: 0;
    left: $width-leftnav;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 40px;
    box-sizing: border-box;
    z-index: 10;
}

.topheader-title {
    font-weight: 700;
    font-size: 24px;
    text-transform: capitalize;
}

.profile-text {
    font-size: 16px;
    font-weight: 600;
    margin-right: 18px;
}

.profile-image {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    margin-right: 12px;
    object-fit: cover;
}
