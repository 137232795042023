@import 'styles/variables';
@import 'styles/mixins';

.video-player-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: 50%;
    overflow: hidden;
    position: relative;
}

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    flex-shrink: 0;
}

video {
    width: 100%;
}

.controls {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    position: absolute;
    box-sizing: border-box;
    bottom: 30px;
    padding: 14px;
    width: calc(100% - 20px);
    max-width: 500px;
    background: rgba(255, 255, 255, 0.25);
    box-shadow: 0 8px 32px 0 rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.18);
    transform: translateY(150%);
    transition: all 0.3s ease-in-out;
}

.actions {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -60%);
}

.pause-button {
    transition: 200ms;
    opacity: 0;
    pointer-events: none;
}

.video-wrapper {
    width: 100%;
    max-width: 650px;
    height: 325px;
    background-color: #000;
    position: relative;
    display: flex;
    justify-content: center;
    overflow: hidden;
    border-radius: 10px;

    &:hover {
        .controls {
            transform: translateY(0%);
        }

        .pause-button {
            opacity: 1;
            pointer-events: all;
        }
    }
}

.video-player-icon {
    width: 70px;
    height: 70px;
    cursor: pointer;
}

.video-mute-icon {
    width: 20px;
    height: 20px;
    margin-left: 10px;
    cursor: pointer;
}

.actions button {
    width: 100%;
    height: 100%;
    display: grid;
    place-items: center;
    background: none;
    border: none;
    outline: none;
}

input[type='range'] {
    -webkit-appearance: none;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 4px;
    height: 6px;
    flex-grow: 1;
    cursor: pointer;

    &::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        cursor: pointer;
        height: 16px;
        width: 16px;
        background: #FF7268;
        border-radius: 8px;
    }

    &::-moz-range-thumb {
        height: 16px;
        width: 16px;
        border-radius: 8px;
        background: #FF7268;
        cursor: pointer;
    }
}

.velocity {
    appearance: none;
    background: none;
    color: black;
    cursor: pointer;
    outline: none;
    border: none;
    text-align: center;
    font-size: 16px;

    option {
        padding: 5px 10px;
    }
}

.mute-btn {
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
    margin-right: 10px;
}
