@import 'styles/variables';
@import 'styles/mixins';

.selected-category {
    display: flex;
    align-items: center;
    padding: 8px 12px;
    height: 30px;
    border-radius: 20px;
    background-color: $color-red-1;
    box-sizing: border-box;
    margin-right: 10px;
    margin-top: 10px;

    span {
        color: $color-white-1;
        margin-right: 10px;
        font-size: 14px;
    }
}

.categories-wrapper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 0 10px;
    padding-bottom: 10px;
    min-height: 50px;
    border: 1px solid $color-gray-3;
    border-radius: 8px;
    background-color: $color-white-2;

    &::after {
        position: absolute;
        top: 20px;
        right: 16px;
    }
}

.close-icon {
    height: 18px;
}

.dropdown-toggle,
.dropdown-menu {
    width: 100%;
}

.form-input {
    height: 50px;
    padding: 16px 20px;
    border-radius: 8px;
    background-color: $color-white-2;
    border: 1px solid $color-gray-3;
    width: 100%;
    outline: 0;
    font-size: 14px;
}

.form-input-borderless {
    height: 50px;
    padding: 16px 20px;
    border-radius: 8px;
    background-color: $color-white-2;
    border: 0;
    width: 100%;
    outline: 0;
    font-size: 14px;
}

.organizer-input {
    background-color: $color-white-2;
    border: 0;
    outline: 0;
    font-size: 14px;
    margin-top: 12px;
    margin-left: 10px;
    width: 100%;
}

.form-textarea {
    padding: 16px 20px;
    border-radius: 8px;
    background-color: $color-white-2;
    border: 1px solid $color-gray-3;
    width: 100%;
    outline: 0;
    font-size: 14px;
}

.form-type-select {
    height: 50px;
    padding: 10px 20px;
    border-radius: 8px;
    background-color: $color-white-2;
}

.form-label {
    font-size: 16px;
    font-weight: 600;
    color: black;
    width: 100%;

    &-wrapper {
        display: flex;
        align-items: center;
    }

    &-gray {
        color: $color-gray-3;
        margin-right: 16px;
        margin-bottom: 8px;
        white-space: nowrap;
    }

    &-red {
        color: $color-red-1;
        margin-bottom: 8px;
        flex-shrink: 0;
        white-space: nowrap;
    }

    &-info {
        color: $color-gray-2;
        font-size: 15px;
        font-weight: 400;
    }

    &-count {
        color: $color-gray-2;
        font-size: 13px;
        font-weight: 400;
    }

    &-section {
        font-size: 20px;
    }

    &-big {
        font-size: 20px !important;
        font-weight: 600;
        color: black;
    }
}

.challenge-type {
    width: 100%;
    margin-top: 13px;
    margin-left: 10px;
    font-size: 14px;
    color: $color-gray-2;
    text-align: left;
}

.selected-type-text {
    font-weight: 600;
    color: $color-gray-1;
}

.dates-input-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.date-wrapper {
    padding: 16px 20px;
    height: 50px;
    border: 1px solid $color-gray-3;
    border-radius: 8px;
    background-color: $color-white-2;
    display: flex;
    align-items: center;

    &-input {
        outline: 0;
        border: 0;
        background-color: $color-white-2;
        font-size: 14px;
        font-weight: 500;
    }
}

.category-header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 50px;
}

.category-dropdown {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 16px;

    &-wrapper {
        flex-grow: 1;
        max-width: 50%;
    }

    &-add {
        cursor: pointer;
        color: $color-red-1;
        font-weight: 600;
        font-size: 16px;
        flex-grow: 0;
        flex-shrink: 0;
    }

    &-disabled {
        color: $color-red-1;
        pointer-events: none;
        filter: brightness(150%);
        font-weight: 600;
        font-size: 16px;
        flex-grow: 0;
        flex-shrink: 0;
    }
}

.winner-dropdown {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-wrapper {
        flex-grow: 1;
        margin-right: 20px;
    }
}

.winners-form-title {
    width: 100%;
    margin-top: 40px;
    padding-top: 40px;
    border-top: 8px solid #EDEDED;
    font-size: 20px;
    font-weight: 700;
    text-align: center;
    position: relative;
}

.winners-form-info {
    width: 100%;
    margin-top: 20px;
    font-size: 16px;
    color: $color-gray-2;
    font-weight: 500;
    text-align: center;
}

.edit-winners-button {
    position: absolute;
    bottom: 0;
    padding-bottom: 3px;
    right: 0;
    color: $color-red-1;
    font-weight: 400;
    font-size: 16px;
}

.choose-winners-button {
    position: absolute;
    top: 40px;
    padding-bottom: 3px;
    right: 0;
    color: $color-red-1;
    font-weight: 400;
    font-size: 16px;
}

.winner-margin-left {
    padding-left: 40px;
    margin-top: 20px;
    padding-bottom: 40px;
    border-bottom: 5px solid #ededed;
}

.form-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 70px;
}

.space-between {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.form-error-message {
    color: $color-red-1;
    font-size: 14px;
    font-weight: 500;
    margin-top: 10px;
}

.image-placeholder-wrapper {
    width: 200px;
    height: 200px;
    border-radius: 10px;
    border: 2px dashed $color-gray-3;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 12px;

    span {
        font-size: 14px;
        font-weight: 300;
        color: $color-gray-3;
        margin-top: 8px;
    }
}

.form-image-wrapper {
    position: relative;
    display: inline-block;
}

.form-input-image {
    width: 200px;
    height: 200px;
    border-radius: 10px;
    object-fit: cover;
    border: 1px solid $color-gray-4;

    &-close {
        width: 25px;
        height: 25px;
        background-color: $color-red-1;
        border-radius: 50%;
        display: grid;
        place-items: center;
        position: absolute;
        right: 15px;
        top: 15px;
        z-index: 2;
        cursor: pointer;
    }
}

.form-banner-image {
    height: 200px;
    border-radius: 10px;
}
