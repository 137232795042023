@import 'styles/variables';
@import 'styles/mixins';

.leftnav-container {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    z-index: 11;
    width: $width-leftnav;
    background-color: $color-red-1;
    box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: 200ms;
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom: 50px;
}

.nav-button {
    margin-top: 24px;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 200ms;
    cursor: pointer;
    position: relative;
    padding: 16px;

    &::after {
        padding: 6px 12px;
        background-color: $color-gray-1;
        color: $color-white-1;
        border-radius: 6px;
        box-shadow: rgba(50, 50, 93, 0.25) 0 13px 27px -5px, rgba(0, 0, 0, 0.3) 0 8px 16px -8px;
        font-size: 16px;
        font-size: 700;
        position: absolute;
        top: 50%;
        left: 100%;
        transform: translate(20px, -50%);
        opacity: 0;
    }

    &:hover::after {
        opacity: 1;
    }

    &:hover {
        background-color: rgba(255, 255, 255, 0.4);
    }
}

.nav-button-active {
    margin-top: 24px;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    transition: 200ms;
    cursor: pointer;
    background-color: $color-white-1;
    padding: 16px;
}

.nav-icon-text {
    font-size: 16px;
    font-weight: 700;
    color: $color-white-2;
    white-space: nowrap;
    width: 0;
    opacity: 0;
    transition: 200ms;
}

.yoripe-text {
    width: 0;
    opacity: 0;
    transition: all 0.2s ease-in-out;
}

.leftnav-container-active {
    width: 230px;

    .nav-button,
    .nav-button-active {
        width: calc(100% - 28px);
        border-radius: 14px;
        justify-content: start;
        box-sizing: border-box;

        &:hover::after {
            opacity: 0;
        }
    }

    .nav-icon-text {
        width: auto;
        opacity: 1;
        margin-left: 18px;
    }

    .yoripe-text {
        width: auto;
        opacity: 1;
        margin-left: 8px;
        margin-top: 8px;
    }
}

.yoripe-logo-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
}

.tooltip-icon-recipe::after {
    content: 'Recipes';
}

.tooltip-icon-story::after {
    content: 'Cooking Story';
}

.tooltip-icon-event::after {
    content: 'Events';
}

.tooltip-icon-product::after {
    content: 'Product';
}

.tooltip-icon-challenge::after {
    content: 'Challenge';
}

.tooltip-icon-brand::after {
    content: 'Brand Portal';
}

.tooltip-icon-banner::after {
    content: 'Banner';
}

.tooltip-icon-collection::after {
    content: 'Top Collections';
}

.expand-button {
    margin-top: 24px;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 200ms;

    &:hover {
        background-color: rgba(255, 255, 255, 0.4);
    }
}
