@import 'styles/variables';
@import 'styles/mixins';

.confirm-popup-header {
    display: flex;
    padding: 20px;
    flex-direction: row;
    padding-top: 40px;
}

.confirm-icon-delete {
    width: 70px;
    height: 70px;
    background-color: rgba(231, 76, 60, 0.2);
    border-radius: 35px;
    display: grid;
    flex-shrink: 0;
    margin-right: 16px;
    place-items: center;
}

.confirm-icon-win {
    width: 70px;
    height: 70px;
    background-color: rgba(184, 219, 202, 0.2);
    border-radius: 35px;
    margin-right: 16px;
    flex-shrink: 0;
    display: grid;
    place-items: center;
}

.confirm-info {
    display: flex;
    flex-direction: column;

    &-title {
        font-weight: 600;
        font-size: 26px;
    }

    &-description {
        font-weight: 500;
        font-size: 18px;
        color: #757575;
    }
}

.no-border {
    border: 0 !important;
}

.confirm-modal {
    border-radius: 16px;
}
