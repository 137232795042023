@import 'styles/variables';
@import 'styles/mixins';

.input-unit-wrapper {
    height: 50px;
    border-radius: 8px;
    background-color: $color-white-2;
    border: 1px solid $color-gray-3;
    width: 100%;
    font-size: 14px;
    display: flex;
    align-items: center;
    overflow: hidden;

    input {
        flex-grow: 1;
        height: 100%;
        padding: 16px 20px;
        outline: 0;
        border: 0;
        background-color: $color-white-2;
    }

    div {
        flex-shrink: 0;
        height: 100%;
        padding: 16px 20px;
        font-size: 14px;
        font-weight: 600;
        background-color: rgba(196, 196, 196, 0.35);
        color: $color-gray-2;
        line-height: 18px;
    }

    &-disabled {
        background-color: rgba(196, 196, 196, 0.15) !important;
        color: $color-gray-3 !important;
        pointer-events: none;
    }
}

.form-radio {
    display: flex;
    align-items: center;
    margin-top: 10px;
    margin-left: 10px;

    &-label {
        margin-left: 10px;
        font-size: 14px;
        color: #000;
        font-weight: 600;
    }

    &-select {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 2px solid $color-gray-3;
        box-sizing: border-box;
        transition: 200ms;
        display: grid;
        place-items: center;

        &::after {
            content: '';
            width: 0;
            height: 0;
            border-radius: 50%;
            background-color: $color-red-1;
            transition: 200ms;
        }
    }

    &-active {
        border-color: $color-red-1;

        &::after {
            content: '';
            width: 12px;
            height: 12px;
        }
    }
}

.form-checkbox-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 10px;
    margin-top: 10px;
}

.form-checkbox {
    width: 20px;
    height: 20px;
    border-radius: 3px;
    border: 1px solid $color-gray-3;
    box-sizing: border-box;
    transition: 200ms;
    overflow: hidden;

    &::after {
        content: '\2713';
        width: 0;
        height: 0;
        transition: 200ms;
        font-size: 12px;
        color: #fff;
        font-weight: 600;
        display: grid;
        place-items: center;
    }

    &-active {
        background-color: $color-red-1;
        border-color: $color-red-1;

        &::after {
            width: 100%;
            height: 100%;
        }
    }
}

.ingredient-wrapper {
    display: flex;
    align-items: center;
    margin-top: 10px;
    width: 100%;
    height: 50px;
    border-radius: 8px;
    background-color: $color-white-2;
    padding: 0 12px;
    box-shadow: rgba(50, 50, 93, 0.25) 0 2px 5px -1px, rgba(0, 0, 0, 0.3) 0 1px 3px -1px;
}

.ingredient-edit {
    border-radius: 8px;
    margin-top: 10px;
    background-color: #FFFEE8;
    padding: 24px;
    box-shadow: rgba(50, 50, 93, 0.25) 0 2px 5px -1px, rgba(0, 0, 0, 0.3) 0 1px 3px -1px;
}

.ingredient-name {
    flex-grow: 1;
    font-size: 16px;
    font-weight: 500;
    color: $color-gray-2;
    margin-left: 12px;
}

.ingredient-comment {
    font-size: 14px;
    font-weight: 500;
    color: $color-gray-3;
    margin-left: 12px;
}

.ingredient-qualtity {
    font-size: 16px;
    font-weight: 500;
    color: $color-gray-2;
    margin-left: 12px;
}

.ingredient-error {
    color: $color-red-1;
}

.ingredient-icon {
    margin-left: 12px;
}

.ingredient-quantity-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}

.button-group {
    display: flex;
    align-items: center;
}

.ingredients-dragable {
    list-style: none;
    padding: 0;
}

.recipe-video {
    width: 100%;
    height: 100%;
    max-height: 350px;
    max-width: 400px;
    background-color: #000;
    border-radius: 8px;
}
