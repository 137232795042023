@import 'styles/variables';
@import 'styles/mixins';

.image-grid-container {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 1fr 1fr 1fr;

    img {
        width: 120px;
        height: 120px;
        border-radius: 10px;
        object-fit: cover;
        border: 1px solid $color-gray-4;
    }
}

.recipe-publisher-image {
    width: 30px;
    height: 30px;
    border-radius: 10px;
    object-fit: cover;
    margin-right: 10px;
}

.data-section-heading {
    font-size: 20px;
    font-weight: 700;
    margin-top: 20px;
}
