@import 'styles/variables';
@import 'styles/mixins';

.challenge-wrapper {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
}

.challenge-header {
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 40px;
}

.challenge-section {
    display: flex;
    margin-top: 20px;

    &-title {
        width: 160px;
        font-size: 16px;
        font-weight: 600;
    }

    &-content {
        flex: 1;
        margin-left: 10px;
        font-size: 15px;
        font-weight: 500;
        max-width: 500px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;

        .challenge-category {
            height: 30px;
            padding: 8px 16px;
            box-sizing: border-box;
            border-radius: 18px;
            background-color: $color-red-1;
            color: $color-white-1;
            font-size: 14px;
        }
    }

    &-caption {
        flex: 1;
        margin-left: 10px;
        font-size: 15px;
        font-weight: 500;
        max-width: 500px;

        .challenge-category {
            height: 30px;
            padding: 8px 16px;
            box-sizing: border-box;
            border-radius: 18px;
            background-color: $color-red-1;
            color: $color-white-1;
            font-size: 14px;
        }
    }

    &-comment {
        font-size: 15px;
        font-weight: 400;
        color: $color-gray-2;
    }

    &-media {
        width: 200px;
        height: 200px;
        border-radius: 10px;
        object-fit: cover;
        border: 1px solid $color-gray-4;
    }

    &-video {
        width: 400px;
    }
}

.challenge-footer {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    margin-top: 60px;
    margin-bottom: 50px;
}
