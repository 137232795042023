@import 'styles/variables';
@import 'styles/mixins';

.fullscreen-page-container {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 20;
    background-color: $color-white-2;
}

.login-wrapper {
    width: 570px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $color-white-1;
    padding: 30px;
    box-sizing: border-box;
    border-radius: 16px;
    box-shadow: rgba(0, 0, 0, 0.15) 0 5px 15px 0;

    &-logo {
        display: block;
        width: 150px;
    }

    &-title {
        font-weight: 600;
        font-size: 30px;
        color: #2F3044;
        margin-top: 20px;
    }

    &-info {
        font-weight: 300;
        font-size: 18px;
        text-align: center;
        padding: 0 25px;
    }

    @include screen-sm {
        width: 100%;
        padding: 20px;
        box-sizing: border-box;

        &-title {
            font-size: 24px;
        }

        &-info {
            font-weight: 300;
            font-size: 14px;
            text-align: center;
            padding: 0;
        }
    }
}

.input-wrapper {
    width: 100%;
    margin-top: 25px;
    position: relative;

    .input-title {
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        color: #2F3044;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        &-option {
            color: #FA7268;
        }
    }

    .input-base {
        width: 100%;
        height: 60px;
        background: #EEF1F5;
        border-radius: 12px;
        outline: 0;
        border: 0;
        padding: 15px;
        box-sizing: border-box;
        font-size: 16px;

        @include screen-sm {
            height: 55px;
            border-radius: 8px;
            font-size: 16px;
        }
    }

    &-icon {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        display: grid;
        place-items: center;
        transition: 200ms;
        position: absolute;
        right: 20px;
        top: 36px;
        cursor: pointer;

        @include screen-sm {
            top: 29px;
        }

        &:hover {
            background-color: #d5dbe3;
        }
    }

    @include screen-sm {
        margin-top: 15px;
    }
}

.login-button {
    outline: 0;
    border: 0;
    width: 100%;
    height: 50px;
    display: grid;
    place-items: center;
    background: #FA7268;
    border-radius: 6px;
    font-size: 16px;
    color: #fff;
    font-weight: 600;
    margin-top: 30px;

    @include screen-sm {
        margin-top: 20px;
    }
}
