@import 'styles/variables';

/* The following block can be included in a custom.scss */

/* make the customizations */
$theme-colors: (
    'red-1': $color-red-1,
    'white-1': $color-white-1,
    'danger': #BB2D3B,
);

/* import bootstrap to set changes */
@import '~bootstrap/scss/bootstrap';

body {
    font-family: $font-family-1;
}

tr {
    cursor: pointer;
}

.btn-brand-red {
    background-color: $color-red-1;
    color: $color-white-1;
    transition: 200ms;
    font-size: 14px;
    font-weight: 500;
    border-radius: 10px;

    &:hover {
        background-color: $color-red-1;
        color: $color-white-1;
        filter: brightness(80%);
    }
}

.btn-brand-red-hollow {
    background-color: $color-white-2;
    color: $color-red-1;
    border: 1px solid $color-red-1;
    transition: 200ms;
    font-size: 14px;
    font-weight: 500;
    border-radius: 10px;

    &:hover {
        background-color: $color-white-1;
        color: $color-red-1;
        filter: brightness(80%);
    }
}

.btn-red-hollow {
    color: $color-red-1;
    transition: 200ms;
    font-size: 16px;
    font-weight: 600;
    padding: 10px;
    display: inline;

    &:hover {
        filter: brightness(80%);
    }
}

.btn-gray_hollow {
    color: $color-gray-2;
    transition: 200ms;
    font-size: 16px;
    font-weight: 600;
    padding: 10px;
    display: inline;

    &:hover {
        filter: brightness(80%);
    }
}

.btn-brand-gray {
    background-color: $color-gray-2;
    color: $color-white-1;
    transition: 200ms;
    font-size: 14px;
    border-radius: 10px;
    font-weight: 500;

    &:hover {
        background-color: $color-gray-2;
        color: $color-white-1;
        filter: brightness(80%);
    }
}

.page-wrap {
    position: fixed;
    top: $height-topbar;
    left: $width-leftnav;
    z-index: 0;
    height: calc(100% - #{$height-topbar});
    width: calc(100% - #{$width-leftnav});
    overflow-y: auto;
    background-color: $color-white-1;
}

.color-brand {
    color: $color-red-1;
}

.color-brand-imp {
    color: $color-red-1 !important;
}

.hoverable {
    transition: 200ms;
    filter: brightness(100%);
    cursor: pointer;

    &:hover {
        filter: brightness(96%);
    }
}

.unselectable {
    -moz-user-select: -moz-none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
    user-select: none;
}

.invis-scroll {
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }
}

.shrink-none {
    flex-shrink: 0;
}

.pretty-scroll {
    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
    }

    &::-webkit-scrollbar-track {
        border-radius: 10px;
        background: rgba(0, 0, 0, 0.1);
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: rgba(0, 0, 0, 0.2);
    }

    &::-webkit-scrollbar-thumb:hover {
        background: rgba(0, 0, 0, 0.4);
    }

    &::-webkit-scrollbar-thumb:active {
        background: rgba(0, 0, 0, 0.9);
    }
}

.disabled {
    filter: brightness(150%);
    pointer-events: none;
}

.no-link {
    text-decoration: none;
    color: inherit;
}

.underline {
    text-decoration: underline;
}

.content-pages-wrapper {
    width: calc(100% - #{$width-leftnav});
    height: calc(100% - #{$height-topbar});
    position: fixed;
    top: $height-topbar;
    left: $width-leftnav;
    overflow-y: scroll;
    overflow-x: hidden;
    background-color: $color-white-1;
    padding: 0 60px;
    padding-top: 30px;
    box-sizing: border-box;
}

.page-wrapper {
    width: 100%;
    border-radius: 10px;
    background-color: $color-white-2;
    padding: 30px;
    box-sizing: border-box;
    box-shadow: 0 5px 34px rgba(0, 0, 0, 0.09);
}

.form-wrapper {
    width: 100%;
    max-width: 650px;
    margin: 0 auto;
}

.align-left {
    text-align: left;
}

.download-icon {
    position: absolute;
    top: 10px;
    right: 15px;
    cursor: pointer;
    display: grid;
    place-items: center;
    width: 35px;
    height: 35px;
    border-radius: 18px;
    transition: 200ms;
    background-color: rgba(255, 255, 255, 1);

    &:hover {
        background-color: rgba(255, 255, 255, 0.8);
    }
}

.download-icon-small {
    position: absolute;
    top: 5px;
    right: 10px;
    cursor: pointer;
    display: grid;
    place-items: center;
    width: 30px;
    height: 30px;
    border-radius: 15px;
    transition: 200ms;
    background-color: rgba(255, 255, 255, 1);

    &:hover {
        background-color: rgba(255, 255, 255, 0.8);
    }
}

.download-icon-below {
    position: absolute;
    top: 55px;
    right: 15px;
    cursor: pointer;
    display: grid;
    place-items: center;
    width: 35px;
    height: 35px;
    border-radius: 18px;
    transition: 200ms;
    background-color: rgba(255, 255, 255, 1);

    &:hover {
        background-color: rgba(255, 255, 255, 0.8);
    }
}

.position-relative {
    position: relative;
}

.data-tabs-wrapper {
    display: flex;
    align-items: center;
    margin-top: 40px;
}

.data-tab-active {
    border-radius: 10px 10px 0 0;
    display: grid;
    place-items: center;
    font-weight: 600;
    width: 160px;
    height: 60px;
    margin-right: 10px;
    font-size: 16px;
    color: white;
    background-color: #FF7268;
    cursor: pointer;
}

.data-tab-inactive {
    border-radius: 10px 10px 0 0;
    display: grid;
    place-items: center;
    margin-right: 10px;
    cursor: pointer;
    width: 160px;
    height: 60px;
    font-weight: 600;
    font-size: 16px;
    background-color: #C2C2C2;
}

.copy-link-button {
    width: 40px;
    height: 40px;
    background-color: #6fc276;
    border-radius: 6px;
    display: inline-grid;
    place-items: center;
    cursor: pointer;
    margin-right: 10px;
}

.open-in-web {
    width: 40px;
    height: 40px;
    background-color: #6488EA;
    border-radius: 6px;
    display: inline-grid;
    place-items: center;
    cursor: pointer;
}

.admin-panel-duplicate {
    padding: 12px;
    border-radius: 8px;
    background-color: #FEF1D9;
    border: 1px solid #FFA800;
    display: flex;
    flex-direction: row;
    align-items: center;

    &-info {
        font-size: 14px;
        font-size: 600;
        margin-bottom: 8px;

        span {
            margin-left: 8px;
        }
    }

    a {
        display: block;
    }
}

.admin-panel-issue {
    padding: 8px;
    border-radius: 4px;
    background-color: #E74C3C;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    margin-top: 8px;
    font-size: 12px;
    color: #fff;

    span {
        margin-left: 8px;
    }
}

.display-flex {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}
