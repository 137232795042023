@import 'styles/variables';
@import 'styles/mixins';


.challenges-header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-create {
        padding: 12px 20px;
        border-radius: 8px;
        background-color: $color-red-1;
        color: $color-white-1 !important;
        font-size: 16px;
        font-weight: 600;
    }
}

.search-input-wrapper {
    padding: 8px 16px;
    border: 1px solid $color-gray-3;
    border-radius: 8px;

    input {
        background-color: $color-white-2;
        border: 0;
        outline: 0;
        font-size: 16px;
        color: $color-gray-2;

        &::placeholder {
            color: $color-gray-4;
        }
    }
}

.challenge-data-wrapper {
    width: 100%;
    margin-top: 18px;
    overflow-x: scroll;
}

.action-icon-wrapper {
    width: 40px;
    height: 40px;
    border: 1px solid $color-gray-3;
    border-radius: 50%;
    display: grid;
    place-items: center;
}

.challenge-name {
    max-width: 300px;
    white-space: nowrap;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    width: 100%;
}

.data-actions-wrapper {
    display: flex;
    align-items: center;
    margin-top: 30px;
}

.data-table-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
}

.page-info {
    font-size: 16px;
}
