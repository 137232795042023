@import 'variables';
@import 'mixins';
@import 'base';
@import 'typography';
@import 'styles/variables';
@import 'styles/mixins';

.recipe-name {
    max-width: 300px;
    white-space: nowrap;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    width: 100%;
}

.recipe-description {
    max-width: 350px;
    white-space: nowrap;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
}

.story-description {
    max-width: 250px;
    white-space: nowrap;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
}

.banner-linkout {
    max-width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.recipe-image-container {
    display: flex;
    align-items: center;
    position: relative;
}

.recipe-image-more {
    width: 40px;
    height: 40px;
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.6);
    display: grid;
    place-items: center;
    color: #fff;
    position: absolute;
    top: 0;
    right: 10px;
    z-index: 10;
}

.recipe-image-icon {
    width: 40px;
    height: 40px;
    border-radius: 5px;
    background-color: $color-gray-1;
    display: grid;
    place-items: center;
    margin-right: 4px;
    object-fit: cover;
}

th {
    white-space: nowrap;
}

.ingredient-section-title {
    font-size: 18px;
    font-weight: 700;
    margin-top: 20px;
}

.dropdown-item {
    width: 100%;
    overflow-x: hidden;
}

.dropdown-item.disabled {
    color: #666;
}

.flex-center {
    display: flex;
    align-items: center;
}

.space-between {
    justify-content: space-between;
}

.preview-media {
    width: 200px;
    height: 200px;
    object-fit: cover;
    margin: 8px;
    border-radius: 8px;
}

.hashtag {
    background-color: #f5f5f5;
    padding: 4px 8px;
    border-radius: 16px;
    margin-right: 8px;
    margin-bottom: 8px;
    display: inline-block;
    font-size: 14px;
}

.image-grid-container {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    margin-top: 16px;
}

.challenge-section-content {
    margin-top: 8px;
    line-height: 1.5;
}
