//-------------------------------------//
// MEDIA QUERIES
$media-lg: 1200px;
$media-md: 992px;
$media-sm: 768px;
$media-xs: 480px;

//-------------------------------------//
// COLORS

$color-red-1: #FF7268;
$color-white-1: #fff;
$color-white-2: #fafafa;

$color-gray-1: #6A6A6A;
$color-gray-2: #818181;
$color-gray-3: #cacaca;
$color-gray-4: #bdbdbd;


//-------------------------------------//
// Width
$width-leftnav: 90px;
$height-topbar: 70px;

//-------------------------------------//
// FONTS

// fonts: base styles
$font-family-1: 'Poppins', 'Georgia', serif;
$font-family-2: 'Poppins', 'Helvetica', 'Arial', sans-serif;

// fonts: weights
$font-light: 300;
$font-normal: 400;
$font-medium: 500;
$font-semibold: 600;
$font-bold: 700;
